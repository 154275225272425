.cross {
    cursor: pointer;
}

.wishlist-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.wishlist-header {

}

@media screen and (max-width: 1300px) {
    .cross {
        cursor: pointer;
    }
}