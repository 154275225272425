:root {
  --christmasRed: #BB2528;
  --christmasGreen: #165B33;
  --christmasGold: #F8B229;
  --white: #fff;
}

.christmas-body {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Style the video: 100% width and height to cover the entire window */
.videoTag {
  position: fixed;
  min-width: 100%;
  min-height: 100vh;
  z-index: -100;
  top: 0;
}

.christmas-navbar {
  height: 50px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wishlist-btn {
  background-color: var(--christmasRed);
  background-image: none;
  color: var(--white);
  border: 1px transparent var(--christmasRed);
  transition: all 0.3 ease-out;
  text-decoration: none;
  opacity: 0.88;
  padding: 10px 20px;
  font-size: 20px;
  transition: all 0.3 ease-out;
  border-radius: 2px;
  cursor: pointer;
  margin: 20px 10px;
  margin-top: auto;
  width: fit-content;
  flex: 1 0 5vw;
}

.christmas-card-container {
  margin: 200px 200px;
  height: fit-content;
  width: fit-content;
  min-width: 400px;
  max-height: fit-content;
}

.wishlist-btn:hover {
  transition: all 0.3 ease-out;
}

.wishlist-card {
  margin-top: auto;
  padding: 10px 20px;
  background-color: var(--christmasGreen);
  font-size: large;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: italic;
  color: var(--christmasGold);
  z-index: 1000;
  border-radius: 7px;
  opacity: 0.95;
}

a {
  color: var(--white);
  background-color: transparent;
  text-decoration: underline;
}

@media screen and (max-width: 960px) {
  .christmas-body {
    height: 100vh;
    width: 100vw;
    display: block;
  }

  .christmas-navbar {
    height: fit-content;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .christmas-card-container {
    margin: 20px 40px;
    height: fit-content;
    width: 70vw;
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }

  .wishlist-btn {
    background-color: var(--christmasRed);
    background-image: none;
    color: var(--white);
    min-width: 100%;
    border: 1px solid var(--primary);
    transition: all 0.3 ease-out;
    display: block;
    text-decoration: none;
    opacity: 0.9;
    padding: 5px 30px;
    font-size: x-large;
    transition: all 0.3 ease-out;
    border-radius: 2px;
    cursor: pointer;
    margin: 5px 20px;
  }

  .wishlist-card {
    width: 70vw;
    padding: 10px 20px;
    background-color: var(--christmasGreen);
    font-size: small;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-style: italic;
    color: var(--christmasGold);
    z-index: 1000;
    border-radius: 7px;
    opacity: 0.95;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: absolute;
    top: 10;
    left: 10;
  }

  .wishlist-body {
    width: 100%;
  }
}
